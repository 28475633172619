html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

@keyframes pulsing-error {
  from {
    background-color: transparent;
  }
  to {
    background-color: red;
  }
}

.pulsing-error {
  animation-name: pulsing-error;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
